import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cancel-refund--unpublish-events"
    }}>{`Cancel, Refund & Unpublish events`}</h1>
    <p>{`Find out the difference between cancelling and unpublishing events and how to issue a refund.`}</p>
    <Alert kind="warning" mdxType="Alert">This functionality is exclusively available to users with Manager or Admin level access permissions.</Alert>
    <h2 {...{
      "id": "cancel-an-individual-booking"
    }}>{`Cancel an Individual Booking`}</h2>
    <ol>
      <li parentName="ol">{`Open the staff view on an event`}</li>
      <li parentName="ol">{`View current bookings`}</li>
      <li parentName="ol">{`Click the red 'x' to cancel a booking for an individual`}</li>
      <li parentName="ol">{`Select 'Cancel Booking' from the dialogue box`}</li>
      <li parentName="ol">{`Click 'Close'`}</li>
    </ol>
    <h2 {...{
      "id": "cancel-an-event"
    }}>{`Cancel an Event`}</h2>
    <ol>
      <li parentName="ol">{`Select the event`}</li>
      <li parentName="ol">{`Click Edit Event`}</li>
      <li parentName="ol">{`Locate the Cancel Event Box on the right-hand side, add an additional message to the box if required`}</li>
      <li parentName="ol">{`Select cancel event, bookings with an email address in the LMS will be sent a cancellation email notification`}</li>
      <li parentName="ol">{`Bookings with no email will need to be contacted by your staff manually`}</li>
      <li parentName="ol">{`You can Un-Cancel an Event, but attendees will not be notified that the event is taking place`}</li>
    </ol>
    <Alert kind="info" mdxType="Alert">If a paid event is cancelled, remember to cancel attendees to issue a refund.</Alert>
    <h2 {...{
      "id": "unpublish-events"
    }}>{`Unpublish Events`}</h2>
    <p>{`Unpublished events will be hidden from public view, but staff can still make bookings. You might use this function instead of the delay for bookings to create a URL for promotions, or for events that are not open for the general public.`}</p>
    <ol>
      <li parentName="ol">{`Select the event`}</li>
      <li parentName="ol">{`Click Edit Event`}</li>
      <li parentName="ol">{`Below the event information, select the Unpublish button`}</li>
      <li parentName="ol">{`An unpublished event will be hidden from the public`}</li>
      <li parentName="ol">{`At any point an event can be re-published by selecting the event, edit, and Publish event`}</li>
      <li parentName="ol">{`Any bookings will still exist on an unpublished event, it just alters the view, so only staff can view the event and make bookings`}</li>
    </ol>
    <h2 {...{
      "id": "issue-a-refund"
    }}>{`Issue a Refund`}</h2>
    <p>{`All refunds must be issued manually by staff when cancelling an event. Even if an event is cancelled, refunds aren't issued until individual tickets are cancelled.`}</p>
    <ol>
      <li parentName="ol">{`View the current bookings on the event you wish to issue refunds for`}</li>
      <li parentName="ol">{`Cancel all Bookings for an event by selecting the red 'x' next to an attendee’s name`}</li>
      <li parentName="ol">{`A dialogue box will appear with information relating to that member`}</li>
      <li parentName="ol">{`Click 'Cancel Booking’`}</li>
      <li parentName="ol">{`Click 'Close'`}</li>
      <li parentName="ol">{`If the member has paid in a branch, you can cancel their booking as above`}</li>
      <li parentName="ol">{`They will then appear in the Cancelled Bookings list`}</li>
      <li parentName="ol">{`Once a cancelled booking receives their refund, select 'Mark as Refunded'`}</li>
      <li parentName="ol">{`Click 'Confirm Refund'`}</li>
      <li parentName="ol">{`Click 'Close'`}</li>
    </ol>
    <Alert kind="info" mdxType="Alert">An easy way to keep track is to check the 'Source'. You can hover on 'Mark as Refunded' for instructions or on 'Refunded' for extra information.</Alert>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/g1FXb79ZB4o" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">Any member can cancel a booking and receive a refund from their account if they pay online and they cancel within the refund deadline.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      